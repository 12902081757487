<template>
  <v-container>
    <v-form @submit.prevent="submitResponse">
      <div>
        <p>
          Suppose the following data were collected by an experimenter who was observing the
          distance traveled by various gasses in a set amount of time through a horizontal glass
          tube at room temperature and pressure. Your Teaching Assistant (TA) will review your
          answers for this question. Please allow up to two days for your TA to review and
          potentially adjust your grade.
        </p>

        <v-simple-table class="mb-6" style="max-width: 300px">
          <thead>
            <tr>
              <td>Gas</td>
              <td>Distance (to the nearest cm)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="question in questionTwoTable" :key="question">
              <td>
                <chemical-latex :content="question.gas" />
              </td>
              <td>
                <stemble-latex :content="question.distance" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          v-for="question in observationQuestions"
          :key="question.inputId"
          class="mt-3 mb-4 pl-3"
        >
          <v-row class="mb-4">
            <stemble-latex :content="question.text" />
          </v-row>
          <s-textarea v-model="inputs[question.inputId]" outlined class="mb-4" />
        </div>
      </div>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMissPreLab11Part2',
  components: {
    ChemicalLatex,
    AiLoadingOverlay,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        observationPartA: null,
        observationPartB: null,
        observationPartC: null,
      },
      questionTwoTable: [
        {
          gas: 'SO2',
          distance: '$50$',
        },
        {
          gas: 'HCl',
          distance: '$66$',
        },
        {
          gas: 'CH4',
          distance: '$100$',
        },
        {
          gas: 'SO3',
          distance: '$44$',
        },
      ],
      observationQuestions: [
        {
          text: 'a) State your observations regarding this data as briefly and to the point as possible.',
          inputId: 'observationPartA',
        },
        {
          text: 'b) Design a hypothesis to explain your observation.',
          inputId: 'observationPartB',
        },
        {
          text: 'c) Suggest an experiment to test your hypothesis.',
          inputId: 'observationPartC',
        },
      ],
    };
  },
};
</script>

<style scoped></style>
